import * as React from 'react'
import {
  StarIcon,
} from '@heroicons/react/24/solid'


const RatingComponent = ({rating, setRating}) => {
  const rateBulk = item => {
    setRating(rating => (rating === item ? 0 : item))
  }
  return (
    <div className="size-24 inline-block overflow-hidden hover:w-[30rem] duration-150 relative ease-out">
      {[1, 2, 3, 4, 5].map(item => (
        <button
          key={item}
          onClick={() => rateBulk(item)}
          className={
            (rating >= item
              ? 'text-white bg-amber-400 '
              : 'hover:bg-gray-600 bg-gray-400 text-white') + ' size-24  '
          }
        >
          <StarIcon className="size-16 m-auto " />
        </button>
      ))}
      <div
        className={
          (rating === 0 ? 'text-transparent' : 'text-amber-400') +
          ' absolute top-0 left-0 size-24 pointer-events-none font-black text-3xl flex flex-row justify-center items-center'
        }
      >
        {rating}
      </div>
    </div>
  )
}

export default RatingComponent
