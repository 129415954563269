import { useState, useEffect, useMemo, useCallback } from 'react'
export const SORTING = [
  { id: 'rating', name: 'Rating', checked: false, order: 'DESC' },
  { id: 'brand', name: 'Brand', checked: false, order: 'ASC' },
  { id: 'model|jewelry_type', name: 'Model/Type', checked: false, order: 'ASC' },
  // { id: 'jewelry_type', name: 'Jewelry Type', checked: false, order: 'ASC' },
  { id: 'price', name: 'Price', checked: false, order: 'DESC' },
  { id: 'case_size_mm', name: 'Case Size', checked: false, order: 'DESC' },
]

export const recalculateSpreads = spreads => {
  console.log('recalculateSpreads', spreads)
  let accumulated = 0
  let newSpreads = spreads.map(spread =>
    spread.map(page => {
      const totalItems = page.rows.reduce((sum, n) => sum + n, 0)
      const skip = accumulated
      accumulated += totalItems
      return { ...page, totalItems, skip }
    })
  )
  return newSpreads
}

export const useSpreadManager = (
  initialPages,
  items,
  setItems,
  setHoldItems,
  issue,
  issueYear,
  catalogSettings,
  catalogSpread
) => {
  const [spreads, setSpreads] = useState(recalculateSpreads([initialPages]))
  const [currentSpread, setCurrentSpread] = useState(0)
  const [sortItems, setSortItems] = useState(SORTING)
  const updateSpreadsConfig = useCallback(pages => {
    console.log('!! updateSpreadsConfig', pages)
    setSpreads(recalculateSpreads([pages]))
  }, [])

  // Recalculate spreads when items change.
  useEffect(() => {
    let itemsTotal = 0
    let itemsPerSpread = 0
    let spreadIndex = 0
    // auto populate spreads
    for (let spreadConfig of spreads) {
      ++spreadIndex
      const rowsP1 = spreadConfig[0].rows || [4, 4, 4]
      const rowsP2 = spreadConfig[1].rows || [4, 4, 4]
      itemsPerSpread =
        rowsP1.reduce((sum, cols) => sum + cols, 0) + rowsP2.reduce((sum, cols) => sum + cols, 0)
      itemsTotal = itemsTotal + itemsPerSpread
      if (items.length <= itemsTotal && spreads.length > spreadIndex) {
        console.log('removing spread', spreadIndex, currentSpread)
        setSpreads(spreads.slice(0, spreadIndex))
        if (spreadIndex < currentSpread) {
          setCurrentSpread(spreadIndex - 1)
        }
        return
      }
    }
    if (items.length > itemsTotal) {
      let spreadConfig = spreads[spreads.length - 1]
      let newSpreads = []
      while (items.length > itemsTotal) {
        newSpreads.push(
          spreadConfig.map(page => ({
            ...page,
            skip: page.skip + (newSpreads.length + 1) * itemsPerSpread,
            number: page.number + 2 * (newSpreads.length + 1),
          }))
        )
        itemsTotal += itemsPerSpread
      }
      console.log('adding spreads', ...spreads, ...newSpreads)
      setSpreads([...spreads, ...newSpreads])
    }
  }, [spreads, items.length])

  // Calculate current spread pages.
  const spread = useMemo(
    () =>
      (spreads[currentSpread] || spreads[0]).map(page => ({
        ...page,
        items: items.slice(page.skip, page.skip + page.totalItems),
      })),
    [spreads, currentSpread, items]
  )

  const changeSpreadPageConfig = (page, config) => {
    let cfg = spreads[currentSpread][page].config || {}
    if(typeof config === 'function') {
      cfg = { ...cfg, ...config(cfg) }
    } else if (typeof config === 'object') {
      cfg = { ...cfg, ...config }
    }
    spreads[currentSpread][page].config = cfg
    setSpreads(recalculateSpreads(spreads))
  }
  const changeSpreadRow = (page, row, value) => {
    let intVal = Math.min(Math.max(parseInt(value), 0), 12)
    if (row === 0) {
      spreads[currentSpread][page].rows = [intVal, ...spreads[currentSpread][page].rows]
    } else if (row > spreads[currentSpread][page].rows.length) {
      spreads[currentSpread][page].rows = [...spreads[currentSpread][page].rows, intVal]
    }
    spreads[currentSpread][page].rows[row - 1] = intVal
    spreads[currentSpread][page].rows = spreads[currentSpread][page].rows.filter(n => n > 0)
    if (spreads[currentSpread][page].rows.length === 0) {
      spreads[currentSpread][page].rows = [4]
    }
    setSpreads(recalculateSpreads(spreads))
  }

  const updatePageNumber = (page, value) => {
    let firstPage = Math.min(Math.max(value - page - 2 * currentSpread, 1), 1000)
    for (let i = 0; i < spreads.length; i++) {
      spreads[i].forEach((page, index) => {
        page.number = firstPage + index + 2 * i
      })
    }
    setSpreads(recalculateSpreads(spreads))
  }

  const deleteSpreads = (deleteItems=true) => {
    console.log('deleteSpreads', spread[1].skip + spread[1].totalItems)
    let removedItems = items.filter((_, indx) => indx >= spread[1].skip + spread[1].totalItems)
    setItems(items => {
      return items.filter((_, indx) => indx < spread[1].skip + spread[1].totalItems)
    })
    if (!deleteItems) {
      setHoldItems(items => [...items, ...removedItems])
    }
  }
  const applySorting = items => {
    // Separate locked and unlocked products.
    const lockedProducts = items
      .map((product, index) => ({ ...product, position: index }))
      .filter(product => product.locked !== undefined)
    const unlockedProducts = items.filter(product => product.locked === undefined)
    const activeSorts = sortItems.filter(sort => sort.checked)
    if (!activeSorts.length) {
      return items
    }
    function getValue(item, key) {
      if (key.includes('|')) {
        const [primary, fallback] = key.split('|')
        // Return primary if it exists and is not empty, else fallback
        return item[primary] !== undefined && item[primary] !== null && item[primary] !== ''
          ? item[primary]
          : item[fallback]
      }
      return item[key]
    }
    const sorted = [...unlockedProducts].sort((a, b) => {
      // Loop through each active sort criteria in order
      for (const sort of activeSorts) {
        const key = sort.id
        const aValue = getValue(a, key)
        const bValue = getValue(b, key)
        let comparison = 0
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          comparison = aValue.localeCompare(bValue)
        } else {
          comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        }
        if (comparison !== 0) {
          return sort.order === 'ASC' ? comparison : -comparison
        }
      }
      return 0
    })
    lockedProducts.forEach(item => {
      const pos = Math.max(0, Math.min(item.position, sorted.length))
      sorted.splice(pos, 0, item)
    })
    return sorted
  }

  const updateSpread = () => {
    setItems(i => applySorting(i))
  }
  const removeDuplicates = () => {
    // let itemsToRemove = []
    // spread.forEach(page => {
    //   itemsToRemove = [..., ...page.items.filter(
    //     item => item.locked === undefined && isUsed(item, page.number)
    //   )
    // })

    setItems(currentItems => {
      return currentItems.filter(
        currentItem => currentItem.locked !== undefined || !isUsed(currentItem, spread[0].number)
      )
    })
    setHoldItems(currentItems => {
      return currentItems.filter(
        currentItem => currentItem.locked !== undefined || !isUsed(currentItem, spread[0].number)
      )
    })
  }

  const addItemToPage = (sku, pageNumber) => {
    if (!pageNumber) return
    pageNumber = parseInt(pageNumber)
    if (Number.isNaN(pageNumber)) return
    if (pageNumber < 1 || pageNumber > 1000) return
    setHoldItems(prevHoldItems => {
      // Find the item to return
      const itemIndex = prevHoldItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        // Item not found in holdItems list; do nothing.
        return prevHoldItems
      }
      const itemToReturn = prevHoldItems[itemIndex]

      const pageIndex = spread.findIndex(page => page.number === pageNumber)
      let pos = spread[pageIndex].skip + spread[pageIndex].totalItems
      let row = spread[pageIndex].rows.length
      if (spread[pageIndex].rows[row - 1] >= 8) {
        changeSpreadRow(pageIndex, row + 1, 1)
      } else {
        changeSpreadRow(pageIndex, row, spread[pageIndex].rows[row - 1] + 1)
      }
      // Add the item back to the items list
      setItems(prevItems => {
        prevItems.splice(pos, 0, itemToReturn)
        return prevItems
      })

      // Remove the item from the holdItems list
      return prevHoldItems.filter(item => item.sku !== sku)
    })
  }
  const isUsed = (item, pageNumber) => {
    const currentMonthPages = (item.usages || []).filter(
      path =>
        path.startsWith(`/${issueYear} -`) &&
        path.toLowerCase().includes((issue || '').toLowerCase())
    )
    const spreads = currentMonthPages
      .map(path => {
        const regex = /(\d+)[\s-]+(\d+)/
        const match = path.match(regex)
        if (match) {
          // Convert the captured strings to numbers
          return [parseInt(match[1], 10), parseInt(match[2], 10)]
        }
        return [path, path]
      })
      .filter(spread => spread !== null)
    // 3. Filter out spreads that include the provided pageNumber
    const otherSpreads = spreads.filter(spread => !spread.includes(pageNumber))

    // 4. Return true if there are any spreads not containing the pageNumber
    if (otherSpreads.length > 0) return true

    // 5. Check current catalog spreads (other than the current spread)
    //console.log('isUsed', catalogSpread, catalogSettings)
    if (!catalogSettings?.spreads) return false
    let currentSpreads = catalogSettings.spreads.filter(
      spread => (catalogSpread?.id || -1) !== spread.id && spread.items
    )
    //console.log('** currentSpreads', currentSpreads)

    return currentSpreads.some(spread =>
      (spread?.items?.items || []).some(
        spreadItem => spreadItem.sku.toLowerCase() === item.sku.toLowerCase()
      )
    )
  }

  return {
    spread,
    spreads,
    currentSpread,
    setCurrentSpread,
    changeSpreadRow,
    updatePageNumber,
    deleteSpreads,
    updateSpread,
    sortItems,
    setSortItems,
    removeDuplicates,
    addItemToPage,
    isUsed,
    updateSpreadsConfig,
    changeSpreadPageConfig,
  }
}
