// CatalogMaker.hooks.js
import { useState } from 'react'
import { algoliaSearch, extractFilter, deserializeFacets } from '../../helpers/AlgoliaClient'
// Helper function to split an array into chunks of a given size
function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export const useItemSearch = (setItems, setHoldItems) => {
  const [isWorking, setIsWorking] = useState(false)

  const findItems = async (input, defaultRating) => {
    try {
      setIsWorking(true)
      const lines = input.split('\n')
      const controller = new AbortController()
      const skusToFind = []
      let itemsFound = []
      let neverUsed = false

      for (const line of lines) {
        const trimmed = line.trim()
        if (!trimmed) continue
        if (trimmed.includes('** never used')) {
          neverUsed = true
          continue
        }

        let include = true
        let rating = defaultRating
        let content = trimmed
        const prefixMatch = /^([+-])(\d+)?\s*(.+)$/.exec(trimmed)
        if (prefixMatch) {
          include = prefixMatch[1] === '+'
          if (prefixMatch[2] !== undefined) {
            rating = Number(prefixMatch[2])
          }
          content = prefixMatch[3].trim()
        }
        const urlTest = /https?:\/\/www.grayandsons.com\/(.*?)\//.exec(content)
        if (!urlTest) {
          const skuNumber = /[nswNSW]\d{3,7}/.exec(content)
          if (skuNumber) {
            skuNumber.forEach(sku => {
              const skuInfo = skusToFind.find(_sku => _sku.sku === sku.toUpperCase())
              if (include) {
                if (skuInfo) {
                  skuInfo.include = true
                  skuInfo.rating = rating
                } else {
                  skusToFind.push({ sku: sku.toUpperCase(), include, rating })
                }
              } else {
                if (skuInfo) {
                  skusToFind.splice(skusToFind.indexOf(skuInfo), 1)
                }
                itemsFound = itemsFound.filter(item => item.sku !== sku.toUpperCase())
              }
            })
          }
          continue
        }
        const fullUrl = new URL(content.trim())
        // if(fullUrl.pathname.includes('/catalog-maker')) {
        //   const hash = new URLSearchParams(fullUrl.hash.substring(1))
        //   if(hash.get('data')) {
        //     setData(hash.get('data'))
        //     break
        //   }
        // }
        await fetch('/page-data' + fullUrl.pathname + 'page-data.json')
          .then(res => res.json())
          .then(async data => {
            const categoryId = data.result?.pageContext?.categoryId || null

            const _facets = deserializeFacets(fullUrl.hash)
            const _keyword = (extractFilter(_facets, 'search', false) || '').toString().trim()
            const _sortBy = extractFilter(_facets, 'sort_by', false)
            const _minPrice = Number.parseFloat(extractFilter(_facets, 'min_price', false)) || -1
            const _maxPrice = Number.parseFloat(extractFilter(_facets, 'max_price', false)) || -1
            let _page = parseInt(extractFilter(_facets, 'page'))
            return algoliaSearch(
              _keyword,
              _page,
              _facets,
              _sortBy,
              controller,
              categoryId ? { strapi_id: categoryId } : null,
              true,
              false,
              [_minPrice, _maxPrice],
              1000,
              [],
              true
            ).then(searchResponse => {
              if (include) {
                itemsFound.push(
                  ...searchResponse.hits.map(item => ({ ...item, id: item.sku, rating }))
                )
              } else {
                itemsFound = itemsFound.filter(
                  item => !searchResponse.hits.some(hit => hit.sku === item.sku)
                )
              }
            })
          })
          .catch(err => {
            console.error(`Error fetching ${fullUrl.pathname}`, err)
          })
      }
      if (skusToFind && skusToFind.length > 0) {
        await algoliaSearch(
          '',
          0,
          [],
          '',
          controller,
          null,
          true,
          false,
          [-1, -1],
          1000,
          skusToFind.map(sku => sku.sku),
          true
        ).then(searchResponse => {
          itemsFound.push(
            ...searchResponse.hits.map(item => {
              const itemToFind = skusToFind.find(sku => sku.sku === item.sku.toUpperCase())
              return {
                ...item,
                id: item.sku,
                rating: itemToFind ? itemToFind.rating : defaultRating,
                usages: [],
              }
            })
          )
        })
      }

      // const skus = itemsFound.map(item => item.sku).join(',')
      const skuChunks = chunkArray(itemsFound, 100)
      if (defaultRating >= 0) {
        let asked = false
        for (const chunk of skuChunks) {
          const skus = chunk.map(item => item.sku).join(',')
          // Create a new AbortController for each request
          const controller = new AbortController()
          setTimeout(() => controller.abort(), 15000) // 15 seconds timeout
          try {
            const response = await fetch(
              `https://apps1.graynew.local:3443/Reports/GetProductStatus?skus=${skus}`,
              {
                signal: controller.signal,
              }
            )
            const data = await response.json()

            // Update each item in itemsFound with the returned usages
            data.data.forEach(item => {
              const foundItem = itemsFound.find(i => i.sku === item.sku)
              if (foundItem) {
                foundItem.usages = item.usages || []
              }
            })
            console.log(data)
          } catch (err) {
            console.error(err)
            if(!asked && confirm('Failed to get product status (yewllow overlay - is used status). Please, accept certificate for G&S local server. Do you want to get it there now?')) {
              window.open(`https://apps1.graynew.local:3443/Reports/GetProductStatus?skus=${skus}`)
            }
            asked = true
          }
        }
      }
      if (neverUsed) {
        itemsFound = itemsFound.filter(item => (item?.usages || []).length === 0)
      }
      return itemsFound
    } finally {
      setIsWorking(false)
    }
  }
  const addNewItems = async (url, rating, setUrl) => {
    const itemsFound = await findItems(url, rating)
    console.log(itemsFound)
    if (!itemsFound) return
    setUrl('')
    setItems(currentItems => {
      const mergedItems = [...currentItems, ...itemsFound]
      const uniqueItems = mergedItems.filter(
        (item, index, self) => index === self.findIndex(i => i.sku === item.sku)
      )
      return uniqueItems
    })
  }
  const replaceAllItems = async (url, rating, items) => {
    if (items && items.length > 0) {
      url = url + '\n' + items.map(item => item.sku).join('\n')
    }

    console.log('replace all items - config', url)
    const itemsFound = await findItems(url, rating)
    console.log('itemsFound', itemsFound)
    const uniqueItems = (itemsFound || []).filter(
      (item, index, self) => index === self.findIndex(i => i.sku === item.sku)
    )
    if (items && items.length > 0) {
      const uniqueItemsMap = new Map(uniqueItems.map(u => [u.sku, u]))
      const orderedItems = items.reduce((acc, cur) => {
        if (uniqueItemsMap.has(cur.sku)) {
          const uniqueItem = uniqueItemsMap.get(cur.sku)
          acc.push({
            ...uniqueItem,
            locked: cur.locked !== undefined ? cur.locked : uniqueItem.locked,
          })
          // Remove the item so we know it's already been ordered
          uniqueItemsMap.delete(cur.sku)
        } else {
          acc.push({ ...cur, missing: true, urlPath: '/' + cur.sku })
        }
        return acc
      }, [])

      // Remaining items are those in uniqueItemsMap that were not in the ordered list
      const remainingItems = Array.from(uniqueItemsMap.values())
      setItems(orderedItems)
      setHoldItems(remainingItems)
      console.log('orderedItems', orderedItems)
      console.log('remainingItems', remainingItems)
    } else {
      setItems(uniqueItems)
      setHoldItems([])
      console.log('uniqueItems', uniqueItems)
    }
    // setItems(() => {
    //   // return uniqueItems
    // })
  }
  const removeItems = async (url, setUrl) => {
    // Get the items that should be removed.
    const itemsFound = await findItems(url, -10)
    console.log('Items to remove:', itemsFound)
    if (!itemsFound) return
    setUrl('')

    // Update the current items state by filtering out the items we want to remove.
    setItems(currentItems => {
      // If findItems returns nested arrays, flatten them.
      const itemsToRemove = itemsFound.flat()

      // Remove any current item that matches an item in itemsToRemove based on sku.
      return currentItems.filter(
        currentItem =>
          currentItem.locked !== undefined ||
          !itemsToRemove.some(item => item.sku === currentItem.sku)
      )
    })
  }

  return {
    isWorking,
    addNewItems,
    removeItems,
    replaceAllItems,
  }
}
