import * as React from 'react'
import Icons, { GnsLogo } from '../components/Icons'
import IconsProductPreloader from '../components/products/IconsProductPreloader'
import Seo from '../components/Seo'
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import Spread from '../components/catalog-maker/Spread'
import CatalogIndex from '../components/catalog-maker/CatalogIndex'
import TopQueryBar from '../components/catalog-maker/TopQueryBar'
import SortByBar from '../components/catalog-maker/SortByBar'
import { IconLoading } from '../components/IconsCheckout'
import HoldItems from '../components/catalog-maker/HoldItems'
import { ISSUES, useCatalogSettings } from '../hooks/catalog-maker/useCatalogSettings'
import { useItemManager } from '../hooks/catalog-maker/useItemManager'
import { useItemSearch } from '../hooks/catalog-maker/useItemSearch'
import { useSpreadManager } from '../hooks/catalog-maker/useSpreadManager'
import { useDownloadManager } from '../hooks/catalog-maker/useDownloadManager'
import '../styles/main.scss'
import ProductDetails from '../components/catalog-maker/ProductDetails'
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { ProductCardMock } from '../components/catalog-maker/ProductCard'

const CatalogMaker = () => {
  const [url, setUrl] = React.useState('')
  const pages = [
    { number: 1, rows: [4, 4, 4] }, // totalItems will be 11
    { number: 2, rows: [4, 4, 4] }, // totalItems will be 12
  ]
  const [item, setItem] = React.useState(null)
  const [itemHover, setItemHover] = React.useState(null)
  const [productInfo, setProductInfo] = React.useState(0)
  const {
    catalogSettings,
    issue,
    setIssue,
    issueYear,
    issueNumber,
    slug,
    catalogSpread,
    setCatalogSpread,
    saveSpread,
  } = useCatalogSettings()
  const {
    items,
    holdItems,
    updateItem,
    removeItem,
    holdItem,
    moveAllHoldItems,
    onDragEnd,
    setItems,
    setHoldItems,
    lockItem,
    returnItem,
  } = useItemManager()
  const { isWorking, addNewItems, removeItems, replaceAllItems } = useItemSearch(
    setItems,
    setHoldItems
  )
  const {
    spread,
    spreads,
    currentSpread,
    setCurrentSpread,
    changeSpreadRow,
    changeSpreadPageConfig,
    updatePageNumber,
    deleteSpreads,
    updateSpread,
    sortItems,
    setSortItems,
    removeDuplicates,
    isUsed,
    addItemToPage,
    updateSpreadsConfig,
  } = useSpreadManager(
    pages,
    items,
    setItems,
    setHoldItems,
    issue,
    issueYear,
    catalogSettings,
    catalogSpread
  )
  const { generateHold, generateSpread } = useDownloadManager(issue, issueYear)

  React.useEffect(() => {
    console.log('Catalog Spread', catalogSpread)
    console.log('Spread', spread)
    console.log('Spreads', spreads)
  }, [spread, spreads, catalogSpread])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const [activeItem, setActiveItem] = React.useState(null)

  const findItem = sku => {
    if (sku.startsWith('swap-')) {
      sku = sku.replace('swap-', '')
    }
    let itemInfo = items.find(item => item.sku === sku)
    if (itemInfo) {
      return [itemInfo, false]
    }
    return [holdItems.find(item => item.sku === sku), true]
  }

  return (
    <div className="flex flex-col min-h-screen max-w-[1800px] mx-auto">
      <Icons />
      <IconsProductPreloader />
      <div className="flex flex-row justify-between gap-4 h-12 text-white px-4 w-full max-w-[1800px] mx-auto">
        <a
          href="https://www.grayandsons.com/"
          target="_blank"
          className="block w-1/4"
          rel="noreferrer"
        >
          <GnsLogo
            alt="Pre-owned Watches and Luxury Jewelry by Gray and Sons"
            className="h-[3.43rem] w-[9rem] duration-200 "
          />
        </a>
        <div className="text-2xl text-stone-800 text-bold my-2">CATALOG MAKER</div>
        <div className="text-2xl text-emerald-800 text-bold my-2 w-1/4 text-right">
          {issue} / {issueYear}
        </div>
      </div>
      <Seo title="Catalog Maker" description="Gray and Sons Jewelry | Used rolex" noindex={true} />
      {isWorking && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur flex flex-row justify-center items-center z-[10000] text-white text-4xl">
          <IconLoading className="text-white mr-4 animate-spin " /> PROCESSING
        </div>
      )}
      <TopQueryBar
        updateSpread={updateSpread}
        addNewItems={addNewItems}
        removeItems={removeItems}
        url={url}
        setUrl={setUrl}
        replaceAllItems={catalogSettings?.spreads ? replaceAllItems : null}
      />
      <ProductDetails
        item={itemHover || item}
        setOpen={setProductInfo}
        open={productInfo}
        catalogSpread={catalogSpread}
        spreads={catalogSettings?.spreads || []}
        issue={issue}
        issueYear={issueYear}
      />
      <SortByBar
        totalItems={items.length}
        sortItems={sortItems}
        setSortItems={setSortItems}
        issue={issue}
        issues={ISSUES}
        issueYear={issueYear}
        setIssue={setIssue}
        removeDuplicates={removeDuplicates}
        isUsed={isUsed}
      />

      <CatalogIndex
        catalogSettings={catalogSettings}
        replaceAllItems={replaceAllItems}
        updatePageNumber={updatePageNumber}
        setUrl={setUrl}
        setCatalogSpread={setCatalogSpread}
        catalogSpread={catalogSpread}
        updateSpreadsConfig={updateSpreadsConfig}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => setActiveItem(findItem(active.id))}
        onDragEnd={e => {
          setActiveItem(null)
          onDragEnd(e)
        }}
      >
        <SortableContext items={[...items, ...holdItems]} strategy={rectSortingStrategy} id="sku">
          <Spread
            spread={spread}
            generateSpread={generateSpread}
            holdItem={holdItem}
            removeItem={removeItem}
            lockItem={lockItem}
            currentSpread={currentSpread}
            setCurrentSpread={setCurrentSpread}
            spreads={spreads}
            changeSpreadRow={changeSpreadRow}
            updatePageNumber={updatePageNumber}
            updateItem={updateItem}
            isUsed={isUsed}
            deleteSpreads={deleteSpreads}
            changeSpreadPageConfig={changeSpreadPageConfig}
            sensors={sensors}
            items={items}
            catalogSpread={catalogSpread}
            saveSpread={saveSpread}
            setItem={setItem}
            setItemHover={setItemHover}
            productInfo={productInfo}
          />
          <HoldItems
            holdItems={holdItems}
            sensors={sensors}
            returnItem={returnItem}
            removeItem={removeItem}
            spread={spread}
            catalogSpread={catalogSpread}
            isUsed={isUsed}
            updateItem={updateItem}
            addItemToPage={addItemToPage}
            moveAllHoldItems={moveAllHoldItems}
            generateHold={generateHold}
          />
        </SortableContext>
        <DragOverlay>
          {activeItem ? <ProductCardMock product={activeItem[0]} isOnHold={true} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

export default CatalogMaker
