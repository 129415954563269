// useCatalogSettings.js
import { useState, useEffect, useCallback } from 'react'
import useHashState from '../useHashState'
export const ISSUES = [
  { value: 'January', name: 'January', number: 1 },
  { value: 'February', name: 'February', number: 2 },
  { value: 'Spring', name: 'Spring', number: 3 },
  { value: 'May', name: "May (Mother's Day)", number: 5 },
  { value: 'June', name: "June (Father's Day)", number: 6 },
  { value: 'July', name: 'July (Summer A)', number: 7 },
  { value: 'August', name: 'August (Wrap)', number: 8 },
  { value: 'September', name: 'September', number: 9 },
  { value: 'October', name: 'October', number: 10 },
  { value: 'November', name: 'November', number: 11 },
  { value: 'December', name: 'December (Wrap)', number: 12 },
]

export const useCatalogSettings = () => {
  const [slug] = useHashState('slug')
  // const [data, setData] = useHashState('data')
  const [catalogSettings, setCatalogSettings] = useState({})
  const [catalogSpread, setCatalogSpread] = useState({})
  const [issue, setIssue] = useState('')
  const currentMonth = new Date().getMonth() + 1
  const issueNumber = ISSUES.find(i => i.value === 'issue')?.number || new Date().getMonth() + 1
  const issueYear = catalogSettings?.year
    ? catalogSettings.year
    : currentMonth >= 7 && issueNumber < 7
    ? new Date().getFullYear() + 1
    : new Date().getFullYear()

  let url = '/api/catalog'
  if (typeof window !== 'undefined' && window.location.port == 8000) {
    url = `${window.location.protocol}//${window.location.hostname}:3000/dev/api/catalog`
  }
  const reloadCatalog = useCallback(async (slug, id) => {
    return fetch(`${url}?slug=${slug}`)
      .then(res => res.json())
      .then(data => {
        const catalog = data.data
        console.log('reloadCatalog', catalog)
        setCatalogSettings(catalog)
        setIssue(catalog.issue)
        console.log(
          'id',
          id,
          catalog.spreads.find(s => s.id === id)
        )
        setCatalogSpread(catalog.spreads.find(s => s.id === id) || {})
      })
  }, [])

  useEffect(() => {
    if (!slug) return
    reloadCatalog(slug)
  }, [slug])

  const saveSpread = async (catalogSpread, pages, items, isDone = false) => {
    console.log('saveSpread', catalogSpread, items, pages, isDone)
    const info = {
      spread: {
        id: catalogSpread.id,
        catalog_id: catalogSpread.catalog_id,
        done: isDone,
        items: {
          pages: pages.map(p => ({ rows: p.rows, number: p.number, config: p.config || {} })),
          items: items.map(item => ({
            id: item.id,
            sku: item.sku,
            page: item.page,
            locked: item.locked,
          })),
        },
      },
    }
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(info),
    })
    if (res.ok) {
      const data = await res.json()
      console.log('Saved', data)
      reloadCatalog(slug, data?.spread?.id).then(() => {
        alert('Saved')
      })
    } else {
      console.error('Failed to save', res)
      alert('Failed to save')
    }
  }

  // useEffect(() => {
  //   if (!data) return
  //   const catalog = JSON.parse(data)
  //   const name = (catalog.issue || '').toLowerCase()
  //   const selectedIssue = ISSUES.find(i => name.includes(i.value.toLowerCase())) || ISSUES[0]
  //   const issueYear =
  //     currentMonth >= 7 && selectedIssue.number < 7
  //       ? new Date().getFullYear() + 1
  //       : new Date().getFullYear()
  //   const newSlug = `${issueYear}-${selectedIssue.value.toLowerCase().replace(/[^a-z]+/g, '-')}`
  //   setCatalogSettings({ slug: newSlug, issue: selectedIssue.value, data: catalog.data })
  //   setIssue(selectedIssue.value)
  //   setSlug(newSlug)
  //   setData('')
  // }, [data, currentMonth, setData, setSlug])

  return {
    catalogSettings,
    issue,
    setIssue,
    issueYear,
    issueNumber,
    slug,
    catalogSpread,
    setCatalogSpread,
    saveSpread,
  }
}
