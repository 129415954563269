import * as React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { IconPlus } from '../Icons'
import { getLabel } from '../../helpers/StringHelpers'
import { SortByFields } from '../../helpers/AlgoliaClient'

const getFacetKeyValue = (key, facet) => {
  if (facet.key && facet.value) return facet.key + ':' + facet.value
  return (facet.key || key) + ':' + (facet.value || facet.name)
}

/*
 Handle the facet selection
 It toggles the facet on and off
 For sort_by, it only allows one selection (radio button)
 For other facets, it allows multiple selections (checkbox)
 */
const handleFacetClick = (key, facet, facetFilters) => {
  const filters = { ...facetFilters }
  const kv = getFacetKeyValue(key, facet)
  key = facet.key || key
  delete filters['page']
  if (key === 'sort_by') {
    if (key in filters && filters[key][0] === kv) {
      delete filters[key]
    } else {
      filters[key] = [kv]
    }
    return filters
  }
  if (!(key in filters)) {
    filters[key] = [kv]
    return filters
  }
  const index = filters[key].indexOf(kv)
  if (index === -1) {
    filters[key].push(kv)
    return filters
  }
  filters[key].splice(index, 1)
  if (filters[key].length <= 0) {
    delete filters[key]
  }
  return filters
}

const FilterPanel = ({ facets, facetFilters, handleFacet }) => {
  const initState = {}
  Object.keys(facetFilters).forEach(key => (initState[key] = true))
  const [expanded, setExpanded] = useState(initState)
  const checkChecked = (key, facet) => {
    if (key in facetFilters) {
      const kv = getFacetKeyValue(key, facet)
      if (facetFilters[key].indexOf(kv) >= 0) return true
    }
    return false
  }
  const getPrice = s => {
    try {
      return parseInt(s.split(' ')[0].replace(',', '').replace('$', ''))
    } catch {
      return 0
    }
  }
  const expand = prop => {
    const c = {}
    if (!(prop in expanded)) {
      c[prop] = true
    }
    setExpanded(c)
  }

  const sortedFacets = Object.keys(facets).sort()
  sortedFacets.unshift('sort_by')

  return (
    <>
      {sortedFacets
        .filter(
          key => ['categories.id', 'categories.name'].indexOf(key) === -1
          // && (key === "sort_by" || facets[key]?.filter(value => value.name !== 'N/A').length > 1)
        )
        .map(key => {
          let values =
            key === 'price_filter'
              ? facets[key].sort((a, b) => {
                  return getPrice(a.name) - getPrice(b.name)
                })
              : facets[key]

          if (key === 'sort_by') values = SortByFields
          values = values.filter(value => value.name !== 'N/A')

          return (
            <div key={key} className={'w-full'}>
              <button
                className={
                  'border-b border-solid border-gray-400 py-4 uppercase flex w-full focus:outline-none hover:text-red-800'
                }
                onClick={() => expand(key)}
              >
                <div className={'w-full text-left'}>{getLabel(key)}</div>
                <div className={'text-right'}>
                  {!(key in expanded) ? <IconPlus /> : <div>&ndash;</div>}
                </div>
              </button>
              <div
                className={
                  key in expanded
                    ? 'border-b border-solid border-gray-400 overflow-auto max-h-60 text-gray-700'
                    : 'hidden'
                }
              >
                {values.map((facet, k) => {
                  const input_id = 'check_' + key + '_' + k
                  return (
                    <div key={key + '_' + k} className={'py-2 hover:text-gray-500 accent-gray-500'}>
                      <input
                        type={key === 'sort_by' ? 'radio' : 'checkbox'}
                        id={input_id}
                        checked={checkChecked(key, facet)}
                        onChange={e => {
                          if (handleFacet) {
                            handleFacet(key, facet, e)
                          }
                        }}
                      />
                      <label className={'pl-3 my-auto'} htmlFor={input_id}>
                        {facet.name} {facet.count > 0 && `(${facet.count})`}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
    </>
  )
}

FilterPanel.propTypes = {
  facets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    )
  ).isRequired,
  facetFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handleFacet: PropTypes.func.isRequired,
}

export { handleFacetClick }
export default FilterPanel
