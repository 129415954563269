import {  useCallback } from 'react'
export const downloadFile = (textContent, name = 'download.txt', type = 'text/plain') => {
  const blob = new Blob([textContent], { type })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export const useDownloadManager = (issue, issueYear) => {

  const generateHold = (holdItems) => {
    downloadFile(
      JSON.stringify( holdItems.map(s => s.sku), null, 2),
      'hold.json',
      'application/json'
    )
  }
  const generateSpread = useCallback((spreads, items) => {
    if (!issue) {
      alert('Please select an issue and pages')
      return
    }
    const fileToSkus = {}
    for (const item of items) {
      for (const file of (item.usages||[])) {
        if (!fileToSkus[file]) fileToSkus[file] = new Set()
        fileToSkus[file].add(item.sku)
      }
    }
    const allSkus = new Set(items.map(item => item.sku))
    const selectedFiles = []
    while (allSkus.size > 0) {
      console.log('allSkus', allSkus)
      let bestFile = null
      let bestCount = 0
      for (const [file, skus] of Object.entries(fileToSkus)) {
        const uncoveredCount = Array.from(skus).filter(sku => allSkus.has(sku)).length
        if (uncoveredCount > bestCount || (uncoveredCount === bestCount && bestFile && bestFile.localeCompare(file) < 0)) {
          bestCount = uncoveredCount
          bestFile = file
        }
      }
      console.log('best file', bestFile)
      if (!bestFile) break // In case no file adds any new SKUs (shouldn't happen if data is correct)
      selectedFiles.push({
        file: bestFile,
        skus: Array.from(fileToSkus[bestFile]).filter(sku => allSkus.has(sku)),
      })
      for (const sku of fileToSkus[bestFile]) {
        allSkus.delete(sku)
      }
    }
    let spreadsWithItems = spreads.map(spread => ({
      folder: issueYear + ' - ' + issue,
      name:
        issue +
        ' ' +
        spread[0].number.toString().padStart(2, '0') +
        '-' +
        spread[1].number.toString().padStart(2, '0'),
      pages: spread.map(page => ({
        ...page,
        items: items.slice(page.skip, page.skip + page.totalItems).map(i => i.sku),
      })),
    }))
    let name =
      issue +
      '-' +
      spreadsWithItems[0].pages[0].number +
      '-' +
      spreadsWithItems[spreadsWithItems.length - 1].pages[1].number
    const spreadInfo = {
      folder: issueYear + ' - ' + issue,
      name: issue,
      spreads: spreadsWithItems,
      selectedFiles
    }
    downloadFile(
      JSON.stringify(spreadInfo, null, 2),
      `spreads-${issueYear}-${name.toLowerCase().replace(/[^a-zA-Z0-9]+/, '-')}.json`,
      'application/json'
    )
  })

  return { generateHold, generateSpread }
}
