// useItemManager.js
import { useState, useCallback } from 'react'
import { arrayMove } from '@dnd-kit/sortable'

export const useItemManager = (initialItems = []) => {
  const [items, setItems] = useState(initialItems)
  const [holdItems, setHoldItems] = useState([])

  const updateItem = useCallback((sku, { rating }) => {
    setItems(prev =>
      prev.map(item => (item.sku === sku ? { ...item, rating } : item))
    )
    setHoldItems(prev =>
      prev.map(item => (item.sku === sku ? { ...item, rating } : item))
    )
  }, [])

  const removeItem = useCallback(sku => {
    setItems(prev => prev.filter(item => item.sku !== sku))
    setHoldItems(prev => prev.filter(item => item.sku !== sku))
  }, [])

  const holdItem = useCallback(sku => {
    setItems(prev => {
      const index = prev.findIndex(item => item.sku === sku)
      if (index === -1) return prev
      const itemToHold = prev[index]
      setHoldItems(prevHold => [...prevHold, itemToHold])
      return prev.filter(item => item.sku !== sku)
    })
  }, [])
  const returnItem = useCallback(sku => {
    setHoldItems(prevHoldItems => {
      // Find the item to return
      const itemIndex = prevHoldItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        // Item not found in holdItems list; do nothing.
        return prevHoldItems
      }
      const itemToReturn = prevHoldItems[itemIndex]

      // Add the item back to the items list
      setItems(prevItems => [...prevItems, itemToReturn])

      // Remove the item from the holdItems list
      return prevHoldItems.filter(item => item.sku !== sku)
    })
  }, [])
  const lockItem = useCallback(sku => {
    setItems(prevItems => {
      // Find the item to hold
      const itemIndex = prevItems.findIndex(item => item.sku === sku)
      if (itemIndex === -1) {
        return prevItems
      }
      if (prevItems[itemIndex].locked === itemIndex) delete prevItems[itemIndex].locked
      else prevItems[itemIndex].locked = itemIndex
      return prevItems
    })
  }, [])

  const moveAllHoldItems = useCallback(() => {
    setItems(prev => [...prev, ...holdItems])
    setHoldItems([])
  }, [holdItems])

  const handleDragEndHold = useCallback(event => {
    const { active, over } = event
    if (active.id !== over.id) {
      setHoldItems(prev =>
        arrayMove(prev, prev.findIndex(item => item.id === active.id), prev.findIndex(item => item.id === over.id))
      )
    }
  }, [])

  const handleDragEnd = useCallback(event => {
    const { active, over } = event
    if (active.id !== over.id) {
      setItems(prev =>
        arrayMove(prev, prev.findIndex(item => item.id === active.id), prev.findIndex(item => item.id === over.id))
      )
    }
  }, [])

  const getContainerId = id =>
    items.some(item => item.id === id) ? 'spread'
      : holdItems.some(item => item.id === id) ? 'hold'
        : null

  const swapItems = useCallback(
    (activeId, overId) => {
      const activeContainer = getContainerId(activeId)
      const overContainer = getContainerId(overId)
      if (!activeContainer || !overContainer) return

      const swapInArray = (array, fromIndex, toIndex) => {
        if (fromIndex !== -1 && toIndex !== -1) {
          const newArray = [...array];
          [newArray[fromIndex], newArray[toIndex]] = [newArray[toIndex], newArray[fromIndex]]
          return newArray
        }
        return array
      }

      const getArrayAndSetter = container =>
        container === 'spread' ? [items, setItems] : [holdItems, setHoldItems]
      const [activeArray, setActiveArray] = getArrayAndSetter(activeContainer)
      const [overArray, setOverArray] = getArrayAndSetter(overContainer)
      const activeIndex = activeArray.findIndex(item => item.id === activeId)
      const overIndex = overArray.findIndex(item => item.id === overId)
      if (activeIndex === -1 || overIndex === -1) return
      
      if (activeContainer === overContainer) {
        const setter = activeContainer === 'spread' ? setItems : setHoldItems
        setter(prev => swapInArray(prev, activeIndex, overIndex))
      } else if (activeIndex !== -1 && overIndex !== -1) {
        const activeItem = activeArray[activeIndex]
        const overItem = overArray[overIndex]
        setActiveArray(prev => {
          const newArr = [...prev]
          newArr[activeIndex] = overItem
          return newArr
        })
        setOverArray(prev => {
          const newArr = [...prev]
          newArr[overIndex] = activeItem
          return newArr
        })
      }
    }, [items, holdItems])


  const onDragEnd = useCallback(
    event => {
      const { active, over } = event
      console.log('On Drag End', active, over, event)
      let swap = false
      let activeId = active.id
      if(active.id.startsWith('swap-')) {
        swap = true
        activeId = active.id.replace('swap-', '')
      }
      if (!over || activeId === over.id) return

      // Determine which container the active and over items belong to.
      const activeContainer = getContainerId(activeId)
      const overContainer = getContainerId(over.id)

      if (swap) {
        // If the swap handle was used and the containers differ, swap the items.
        swapItems(activeId, over.id)
      } else {
        // Otherwise, do a regular sort within the same container.
        if (activeContainer === 'spread' && overContainer === 'spread') {
          const activeIndex = items.findIndex(item => item.id === active.id)
          const overIndex = items.findIndex(item => item.id === over.id)
          setItems(prev => arrayMove(prev, activeIndex, overIndex))
        } else if (activeContainer === 'hold' && overContainer === 'hold') {
          const activeIndex = holdItems.findIndex(item => item.id === active.id)
          const overIndex = holdItems.findIndex(item => item.id === over.id)
          setHoldItems(prev => arrayMove(prev, activeIndex, overIndex))
        } else {
          // move between spread and hold
          // Moving between containers
          if (activeContainer === 'spread' && overContainer === 'hold') {
            const activeIndex = items.findIndex(item => item.id === active.id)
            const overIndex = holdItems.findIndex(item => item.id === over.id)
            if (activeIndex !== -1 && overIndex !== -1) {
              const itemToMove = items[activeIndex]
              // Remove from source container
              setItems(prev => {
                const newItems = [...prev]
                newItems.splice(activeIndex, 1)
                return newItems
              })
              // Insert into destination container at the "over" index
              setHoldItems(prev => {
                const newHold = [...prev]
                newHold.splice(overIndex, 0, itemToMove)
                return newHold
              })
            }
          } else if (activeContainer === 'hold' && overContainer === 'spread') {
            const activeIndex = holdItems.findIndex(item => item.id === active.id)
            const overIndex = items.findIndex(item => item.id === over.id)
            if (activeIndex !== -1 && overIndex !== -1) {
              const itemToMove = holdItems[activeIndex]
              // Remove from source container
              setHoldItems(prev => {
                const newHold = [...prev]
                newHold.splice(activeIndex, 1)
                return newHold
              })
              // Insert into destination container at the "over" index
              setItems(prev => {
                const newItems = [...prev]
                newItems.splice(overIndex, 0, itemToMove)
                return newItems
              })
            }
          }
        }
      }
    },
    [items, holdItems, swapItems]
  )

  return { items, holdItems, updateItem, removeItem, holdItem, moveAllHoldItems, handleDragEndHold, handleDragEnd, setItems, setHoldItems, lockItem, returnItem, onDragEnd }
}
