import * as React from 'react'
import PropTypes from 'prop-types'
import { ProductImage } from '../CategoryImage'
import {
  GiftIcon,
  HandRaisedIcon,
  HandThumbUpIcon,
  StarIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
  LockOpenIcon,
  ArrowPathRoundedSquareIcon,
} from '@heroicons/react/24/solid'
import USD from '../../helpers/NumberHelper'
import hasBoxAndPapers from '../../helpers/product/hasBoxAndPapers'
import { useSortable } from '@dnd-kit/sortable'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'

const ProductType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  urlPath: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  small_image: PropTypes.object.isRequired,
  item_type: PropTypes.string,
  item_category: PropTypes.string,
  jewelry_type: PropTypes.string,
  model_no: PropTypes.string,
  case_size_mm: PropTypes.number,
  thumbnail: PropTypes.object.isRequired,
  media_gallery: PropTypes.array.isRequired,
  brand: PropTypes.string,
  model: PropTypes.string,
  price: PropTypes.number,
  special_price: PropTypes.number,
  qty: PropTypes.number,
  productType: PropTypes.string,
  rating: PropTypes.number,
  locked: PropTypes.number,
  missing: PropTypes.bool,
})
export const ProductCardMock = ({ product }) => {
  const isOnSale = product.special_price && product.special_price > 0
  const boxAndPapers = hasBoxAndPapers(product)
  return (
    <div className="w-full relative group mix-blend-multiply bg-white border-4 border-red-600">
      <div className="cursor-grabbing absolute top-0 right-10 size-8 z-[1000] bg-white rounded-full text-emerald-800">
        <ArrowPathRoundedSquareIcon />
      </div>
      <div className="cursor-grabbing absolute top-0 right-0 size-8 z-[1000] bg-white/40 rounded-full text-emerald-800">
        <EllipsisVerticalIcon />
      </div>
      <a
        href={`https://www.grayandsons.com${[product.urlPath]}`}
        target="_blank"
        rel="noreferrer"
        className="w-full object-contain mix-blend-multiply relative block"
      >
        {product.missing ? (
          <div className="h-full w-full flex flex-col justify-center items-center text-xl text-center text-red-800 bg-stone-100">
            <b>#{product.sku}</b>
            <br />
            Out of Stock
            <br />
            or On Hold{' '}
          </div>
        ) : (
          <ProductImage
            image={product.small_image}
            className=" w-full h-full aspect-square border-4 "
          />
        )}
      </a>
      <p className={(isOnSale && 'text-red-700') + ' font-bold text-center text-lg'}>
        {USD(product.price)}
      </p>
      {boxAndPapers && (
        <GiftIcon className="w-12 h-8 bg-green-500 text-white shadow-lg p-1 rounded-b-xl absolute top-0 left-0" />
      )}
      {product.locked !== undefined && (
        <LockClosedIcon className="w-12 h-8 bg-orange-500 text-white shadow-lg p-1 rounded-b-xl absolute top-0 left-12" />
      )}
    </div>
  )
}
const ProductCard = ({  
  product,
  id,
  isOnHold,
  holdItem,
  removeItem,
  lockItem,
  aspectRatio,
  updateItem,
  isUsed,
  spread,
  addItemToPage,
  setItem,
  setItemHover,
  productInfo,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const draggable = useDraggable({ id: `swap-${id}`, // a unique identifier for the draggable
  })


  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const styleDraggable = {
    transform: CSS.Transform.toString( draggable.transform),
    transition: draggable.transition,
  }
  const isOnSale = product.special_price && product.special_price > 0
  const boxAndPapers = hasBoxAndPapers(product)
  const rating = product.rating
  const assignRating = item => {
    let newRating = rating === item ? 0 : item
    updateItem(product.sku, { rating: newRating })
  }
  const [removeAlert, setRemoveAlert] = React.useState(false)
  const [holdAlert, setHoldAlert] = React.useState(false)
  const [isMoving, setIsMoving] = React.useState(false)
  const [lockPosition, setLockPosition] = React.useState(false)
  const [, setLockedChanged] = React.useState(false)
  const [showDetails, setShowDetails] = React.useState(false)
  return (
    <div
        ref={draggable.setNodeRef}
        style={styleDraggable}
        {... draggable.attributes}
    >
      <div
        onMouseEnter={() => {
          setShowDetails(true)
          if (setItemHover) setItemHover(product)
        }}
        onMouseLeave={() => {
          setShowDetails(false)
          if (setItemHover) setItemHover(null)
        }}
        ref={setNodeRef}
        style={style}
        {...attributes}
        className={
          'w-full relative group mix-blend-multiply bg-white ' +
          (isUsed
            ? ' absolute inset-0 bg-gradient-to-b from-yellow-200 to-transparent to-[30%] '
            : '') +
          (isMoving ? ' z-[1004]' : ' z-10')
        }
      >
        <div
          onMouseDown={() => setIsMoving(true)}
          onMouseUp={() => setIsMoving(false)}
          {...draggable.listeners}
          className={
            (isMoving ? 'cursor-grabbing' : 'cursor-grab') +
            ' absolute top-0 right-10 size-8 z-[1000] bg-white/40 rounded-full text-emerald-800'
          }
        >
          <ArrowPathRoundedSquareIcon />
        </div>
        <div
          onMouseDown={() => setIsMoving(true)}
          onMouseUp={() => setIsMoving(false)}
          className={
            (isMoving ? 'cursor-grabbing' : 'cursor-grab') +
            ' absolute top-0 right-0 size-8 z-[1000] bg-white/40 rounded-full text-emerald-800'
          }
          {...listeners}
        >
          <EllipsisVerticalIcon />
        </div>
        <a
          href={`https://www.grayandsons.com${[product.urlPath]}`}
          onClick={e => {
            if (!productInfo) return
            e.preventDefault()
            setItem(product)
          }}
          target="_blank"
          rel="noreferrer"
          className="w-full object-contain mix-blend-multiply relative block"
          style={{ aspectRatio }}
        >
          {product.missing ? (
            <div className="h-full w-full flex flex-col justify-center items-center text-xl text-center text-red-800 bg-stone-100">
              <b>#{product.sku}</b>
              <br />
              Out of Stock
              <br />
              or On Hold{' '}
            </div>
          ) : (
            <ProductImage
              image={product.small_image}
              className=" w-full h-full aspect-square border-4 "
            />
          )}
          {removeAlert && (
            <div
              className={
                'bg-red-500 absolute top-0 left-0 w-full h-full bg-opacity-50 flex justify-center items-center font-black text-white text-3xl drop-shadow-lg'
              }
            >
              REMOVE
            </div>
          )}
          {lockPosition && (
            <div
              className={
                'bg-orange-400 absolute top-0 left-0 w-full h-full bg-opacity-60 flex justify-center items-center font-black text-white text-3xl drop-shadow-lg'
              }
            >
              {product.locked === undefined ? 'LOCK' : 'UNLOCK'}
            </div>
          )}
          {holdAlert && (
            <div
              className={
                'bg-teal-400 absolute top-0 left-0 w-full h-full bg-opacity-60 flex justify-center items-center font-black text-white text-3xl drop-shadow-lg'
              }
            >
              {isOnHold ? 'RETURN' : 'HOLD'}
            </div>
          )}
        </a>
        <p className={(isOnSale && 'text-red-700') + ' font-bold text-center text-lg'}>
          {USD(product.price)}
        </p>
        {boxAndPapers && (
          <GiftIcon className="w-12 h-8 bg-green-500 text-white shadow-lg p-1 rounded-b-xl absolute top-0 left-0" />
        )}
        {product.locked !== undefined && (
          <LockClosedIcon className="w-12 h-8 bg-orange-500 text-white shadow-lg p-1 rounded-b-xl absolute top-0 left-12" />
        )}

        <div className="flex flex-row mx-auto max-w-60 gap-[calc(1%)] opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-14 left-[50%] -translate-x-[50%] w-full justify-around px-[calc(5%)] items-center">
          <button
            onMouseEnter={() => setRemoveAlert(true)}
            onMouseLeave={() => setRemoveAlert(false)}
            onClick={() => removeItem(product.sku)}
            className="w-1/3 max-w-12 aspect-square bg-red-500 border-[3px] border-white text-white shadow-lg p-1 rounded-md "
          >
            <TrashIcon />
          </button>
          {!isOnHold && (
            <button
              onMouseEnter={() => setLockPosition(true)}
              onMouseLeave={() => setLockPosition(false)}
              onClick={() => {
                lockItem(product.sku)
                setLockedChanged(s => !s)
              }}
              className="w-1/3 max-w-12 bg-orange-400 border-[3px] border-white text-white shadow-lg p-1 rounded-md"
            >
              {product.locked !== undefined ? <LockOpenIcon /> : <LockClosedIcon />}
            </button>
          )}
          {isOnHold && (
            <select
              className="bg-red-200"
              onChange={e => addItemToPage(product.sku, e.target.value)}
            >
              <option>♺</option>
              <option value={spread[0].number}>{spread[0].number}</option>
              <option value={spread[1].number}>{spread[1].number}</option>
            </select>
          )}
          <button
            onMouseEnter={() => setHoldAlert(true)}
            onMouseLeave={() => setHoldAlert(false)}
            onClick={() => holdItem(product.sku)}
            className="w-1/3 max-w-12 bg-teal-400 border-[3px] border-white text-white shadow-lg p-1 rounded-md"
          >
            {isOnHold ? <HandThumbUpIcon className="-scale-x-100" /> : <HandRaisedIcon />}
          </button>
        </div>

        <div className="w-8/12 flex flex-row justify-between mx-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300 relative">
          {[1, 2, 3, 4, 5].map(item => (
            <button key={item} onClick={() => assignRating(item)}>
              <StarIcon
                className={
                  (rating >= item ? 'text-amber-400' : 'hover:text-amber-400 text-gray-400') +
                  ' size-6'
                }
              />
            </button>
          ))}
          {showDetails && product.sku[0] === 'W' && (
            <p className="text-center text-sm absolute -bottom-5 left-0">
              {product.case_size_mm}mm - Ref. {product.model_no}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  product: ProductType,
  id: PropTypes.string,
  isOnHold: PropTypes.bool,
  holdItem: PropTypes.func,
  removeItem: PropTypes.func,
  updateItem: PropTypes.func,
  lockItem: PropTypes.func,
  aspectRatio: PropTypes.string,
  isUsed: PropTypes.bool,
  spread: PropTypes.array,
  addItemToPage: PropTypes.func,
  setItem: PropTypes.func,
  setItemHover: PropTypes.func,
  productInfo: PropTypes.object,
}
ProductCardMock.propTypes = {
  product: ProductType,
}

export default ProductCard
