import * as React from 'react'
import { ListBulletIcon, NewspaperIcon } from '@heroicons/react/24/solid'
import ProductCard from './ProductCard'
import PropTypes from 'prop-types'

const HoldItems = ({
  holdItems,
  returnItem,
  removeItem,
  spread,
  updateItem,
  addItemToPage,
  moveAllHoldItems,
  generateHold,
  catalogSpread,
  isUsed
}) => {
  if(!holdItems || holdItems.length <= 0) return null
  return (
    <>
      <div className="overflow-x-auto flex gap-4 flex-row bg-gray-100 w-full *:w-[15rem] *:min-w-[15rem] *:bg-white *:p-3 *:border-b *:border-gray-300 *:rounded-lg p-5 *:mix-blend-normal">
        {holdItems.map(item => (
          <ProductCard
            product={item}
            key={item.sku}
            isOnHold={true}
            id={item.sku}
            holdItem={returnItem}
            removeItem={removeItem}
            updateItem={updateItem}
            spread={spread}
            addItemToPage={addItemToPage}
            isUsed={isUsed(item, catalogSpread ? parseInt(catalogSpread.page) : spread.number)}
          />
        ))}
      </div>
      <div className="w-full h-24 flex flex-row justify-center items-center gap-4 mb-10">
        <button
          onClick={moveAllHoldItems}
          className="w-max bg-green-600 text-white px-4 py-2 text-lg font-bold flex flex-row items-center justify-center gap-2"
        >
          <NewspaperIcon className="size-8" /> RETURN ALL TO SPREAD
        </button>
        <button
          onClick={() => generateHold(holdItems)}
          className="w-max bg-cyan-500 text-white px-8 py-2 text-lg font-bold flex flex-row items-center justify-center gap-2"
        >
          <div className="size-7 flex bg-white text-cyan-500 justify-center items-center stroke-1 stroke-cyan-500">
            <ListBulletIcon className="size-6" />
          </div>
          GENERATE LIST
        </button>
      </div>
    </>
  )
}
HoldItems.propTypes = {
  holdItems: PropTypes.array,
  returnItem: PropTypes.func,
  removeItem: PropTypes.func,
  spread: PropTypes.array,
  updateItem: PropTypes.func,
  addItemToPage: PropTypes.func,
  moveAllHoldItems: PropTypes.func,
  generateHold: PropTypes.func,
}

export default HoldItems
