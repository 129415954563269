import * as React from 'react'
import PropTypes from 'prop-types'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ClipboardDocumentCheckIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/solid'

const SortByOption = ({ id, item, updateItem, updateItemOrder }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const [isMoving, setIsMoving] = React.useState(false)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <div ref={setNodeRef} className="flex flex-row gap-2" style={style} {...attributes}>
      <input
        type="checkbox"
        id={id}
        value={id}
        checked={item.checked}
        onChange={() => updateItem(id, { checked: !item.checked })}
      />{' '}
      <label htmlFor={id}>{item.name}</label>
      <div className="inline-block" onClick={() => updateItemOrder(id)}>
        {item.order === 'ASC' ? (
          <BarsArrowUpIcon className="size-6 text-emerald-800" />
        ) : (
          <BarsArrowDownIcon className="size-6 text-emerald-800" />
        )}
      </div>
      <div
        onMouseDown={() => setIsMoving(true)}
        {...listeners}
        onMouseUp={() => setIsMoving(false)}
        className={
          (isMoving ? 'cursor-grabbing' : 'cursor-grab') + ' size-6 text-emerald-800 inline-block'
        }
      >
        <EllipsisVerticalIcon />
      </div>
    </div>
  )
}
SortByOption.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object,
  updateItem: PropTypes.func,
  updateItemOrder: PropTypes.func,
}

const SortByBar = ({
  totalItems,
  sortItems,
  setSortItems,
  issue,
  setIssue,
  issues,
  // issueYear,
  removeDuplicates,
  isUsed,
}) => {
  const updateItem = (id, data) => {
    setSortItems(sortItems => sortItems.map(item => (item.id === id ? { ...item, ...data } : item)))
  }
  const updateItemOrder = id => {
    setSortItems(sortItems =>
      sortItems.map(item =>
        item.id === id ? { ...item, order: item.order === 'ASC' ? 'DESC' : 'ASC' } : item
      )
    )
  }
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      setSortItems(sortItems => {
        const oldIndex = sortItems.map(item => item.id).indexOf(active.id)
        const newIndex = sortItems.map(item => item.id).indexOf(over.id)

        return arrayMove(sortItems, oldIndex, newIndex)
      })
    }
  }
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={sortItems} strategy={rectSortingStrategy} id="sku">
        <div className="flex flex-row gap-4 my-2 relative h-12 items-center">
          <select className="border rounded-md p-1 pr-3" onChange={e => setIssue(e.target.value)} value={issue}>
            {/* Naming should be "Value" + " " + "PAGE A" + "-" + "Page B". For example: January 22-23.psd */}
            <option value="">SELECT ISSUE</option>
            {issues.map(issue => (
              <option key={issue.value} value={issue.value}>
                {issue.name}
              </option>
            ))}
          </select>
          SORTING:
          {sortItems.map(item => (
            <SortByOption
              key={item.id}
              id={item.id}
              item={item}
              updateItem={updateItem}
              updateItemOrder={updateItemOrder}
            />
          ))}
          <button
            className={
              (isUsed ? 'bg-amber-600' : 'bg-blue-600') +
              '  hover:bg-amber-500 duration-200 text-white py-2 px-3 flex flex-row gap-2 rounded-md'
            }
            onClick={() => removeDuplicates()}
          >
            <ClipboardDocumentCheckIcon className="size-6" /> REMOVE DUPLICATES
          </button>
          <div className="absolute right-0">Total Items: {totalItems}</div>
        </div>
      </SortableContext>
    </DndContext>
  )
}
SortByBar.propTypes = {
  totalItems: PropTypes.number,
  sortItems: PropTypes.array,
  setSortItems: PropTypes.func,
  issue: PropTypes.string,
  setIssue: PropTypes.func,
  issues: PropTypes.array,
  // issueYear: PropTypes.string,
  removeDuplicates: PropTypes.func,
  isUsed: PropTypes.func,
}

export default SortByBar
