import * as React from 'react'
import PropTypes from 'prop-types'
import Pagination from '../products/Pagination'
import {
  BookOpenIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  PlusCircleIcon,
  TrashIcon,
  HandRaisedIcon,
} from '@heroicons/react/24/solid'
import ProductCard from './ProductCard'

const Spread = ({
  spread,
  generateSpread,
  holdItem,
  removeItem,
  lockItem,
  currentSpread,
  setCurrentSpread,
  spreads,
  changeSpreadRow,
  changeSpreadPageConfig,
  updatePageNumber,
  updateItem,
  isUsed,
  deleteSpreads,
  items,
  catalogSpread,
  saveSpread,
  setItem,
  setItemHover,
  productInfo,
}) => {
  let maxCols = 0
  for (let page of spread) {
    for (let row of page.rows) {
      if (row > maxCols) maxCols = row
    }
  }
  const [highlightRow, setHighlightRow] = React.useState(false)

  return (
    <div className="grid grid-cols-2 relative">
      {spread.map((page, pageIndex) => {
        let lastRow = 0
        const getItemPosition = index => {
          let itemsCounted = 0
          for (let row = 0; row < page.rows.length; row++) {
            const rowCount = page.rows[row]
            if (index < itemsCounted + rowCount) {
              // Found the row! Its column is the offset inside the row.
              return { row: row + 1, col: index - itemsCounted + 1, maxCol: rowCount }
            }
            itemsCounted += rowCount
          }
          return { row: page.rows.length, col: 1, maxCol: page.rows[page.rows.length - 1] }
        }

        return (
          <div
            key={page.name}
            className="flex flex-row flex-wrap p-5 relative border shadow-xl gap-x-3 gap-y-5 pb-14 bg-gray-50"
          >
            {page.items.length === 0 && (
              <div className="w-full min-h-[400px] flex col-span-4 row-span-3 text-gray-400 items-center justify-center gap-4">
                This Page Is Empty
              </div>
            )}
            <div
              className={
                'w-12 h-full from-gray-200 to-white absolute top-0 ' +
                (pageIndex > 0
                  ? 'bg-gradient-to-r left-0'
                  : 'bg-gradient-to-l right-0 border-r border-gray-250')
              }
            />
            {page.items.map((item, pageItemIndex) => {
              const pos = getItemPosition(pageItemIndex)
              const newRow = lastRow !== pos.row
              const lockRow = row => {
                let rowItems = page.items.filter(
                  item => getItemPosition(page.items.indexOf(item)).row === row
                )
                if (rowItems.some(item => item.locked === undefined)) {
                  // lock all items in the row
                  //
                  rowItems
                    .filter(item => item.locked === undefined)
                    .forEach(item => {
                      lockItem(item.sku)
                    })
                } else
                  rowItems.forEach(item => {
                    lockItem(item.sku)
                  })
              }
              lastRow = pos.row
              return (
                <div
                  key={item.id}
                  className="flex-grow-0"
                  style={{ width: `calc(100% / ${pos.maxCol} - 12px)` }}
                >
                  {newRow && (
                    <div
                      onMouseEnter={() => setHighlightRow(`${pos.row},${pageIndex}`)}
                      onMouseLeave={() => setHighlightRow(false)}
                      className={
                        (pageIndex === 0 ? '-left-16 text-left' : '-right-16 text-right') +
                        ' absolute w-14 h-8 group z-[100] cursor-pointer '
                      }
                    >
                      <span className="text-xs">COLUMNS</span>
                      <select
                        value={pos.maxCol}
                        className={'border w-14 h-8 rounded-md px-2'}
                        onChange={e => changeSpreadRow(pageIndex, pos.row, e.target.value)}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(columns => (
                          <option key={columns} value={columns}>
                            {columns === 0 ? '\u274C' : columns}{' '}
                          </option>
                        ))}
                      </select>

                      <button onClick={() => lockRow(pos.row)}>
                        <LockClosedIcon className="w-14 h-8 bg-gray-400 hover:bg-orange-500 text-white shadow-lg p-1" />
                      </button>
                    </div>
                  )}
                  {highlightRow === `${pos.row},${pageIndex}` && (
                    <div
                      className={'absolute left-0 w-full bg-gray-200 rounded-md -translate-y-4'}
                      style={{
                        height: 'calc(' + Math.round(100 / page.rows.length) + '% - 30px)',
                      }}
                    />
                  )}
                  <ProductCard
                    product={item}
                    key={item.id}
                    id={item.id}
                    lockItem={lockItem}
                    holdItem={holdItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    aspectRatio={`${maxCols}/${pos.maxCol}`}
                    isUsed={isUsed(
                      item,
                      catalogSpread ? parseInt(catalogSpread.page) : page.number
                    )}
                    setItem={setItem}
                    setItemHover={setItemHover}
                    productInfo={productInfo}
                  />
                </div>
              )
            })}
            <input
              id="left-page-num"
              type="number"
              className={
                'w-20 absolute bottom-5 p-1 ' +
                (pageIndex > 0
                  ? 'text-right right-5 pointer-events-none'
                  : 'left-5 hover:bg-gray-100')
              }
              placeholder="Page #"
              value={page.number}
              onChange={e => updatePageNumber(pageIndex, parseInt(e.target.value))}
            />
            <button
              onClick={() => changeSpreadRow(pageIndex, page.rows.length + 1, maxCols)}
              className={
                (pageIndex > 0 ? '-right-14' : '-left-14') +
                ' text-emerald-700 hover:text-emerald-600 duration-200 absolute bottom-0 '
              }
            >
              <PlusCircleIcon className="size-12" />
            </button>
            <label>
              {/* TODO: Greg this is all you! */}
              <select
                onChange={(e) => changeSpreadPageConfig(pageIndex, (cfg) => ({ ...cfg, layout: e.target.value }))}
                className={
                  (pageIndex > 0 ? 'right-0' : 'left-0') +
                  ' text-emerald-700 hover:text-emerald-600 duration-200 absolute -bottom-16 '
                }
                value={page.config?.layout || ''}
              >
                <option value="">Layout</option>
                <option value="grid">Grid</option>
                <option value="column">Single Column</option>
              </select>
            </label>
          </div>
        )
      })}
      <div className="col-span-2 w-full flex flex-col items-center gap-4 mb-10">
        <div className="flex flex-row gap-4">
          <Pagination
            pageInfo={{
              currentPage: currentSpread + 1,
              perPage: 1,
              totalCount: spreads.length,
            }}
            onClick={p => setCurrentSpread(p - 1)}
          />
          <button
            onClick={() => deleteSpreads()}
            className="bg-red-700 hover:bg-red-600 w-12 overflow-hidden hover:w-[13.25rem] duration-200 text-white px-3 h-10 grid grid-cols-[1fr_auto] my-auto gap-3 rounded-md group items-center"
          >
            <TrashIcon className="size-6 my-auto duration-150 " />
            <nobr className="min-w-max">Delete Other Spreads</nobr>
          </button>
          <button
            onClick={() => deleteSpreads(false)}
            className="bg-amber-600 hover:bg-amber-700 w-12 overflow-hidden hover:w-[13.25rem] duration-200 text-white px-3 h-10 grid grid-cols-[1fr_auto] my-auto gap-3 rounded-md group items-center"
          >
            <HandRaisedIcon className="size-6 my-auto duration-150 " />
            <nobr className="min-w-max">Hold Other Items</nobr>
          </button>
        </div>
        <div className="flex flex-row gap-4">
          <button
            onClick={async () => {
              generateSpread(spreads, items)
              saveSpread(catalogSpread, spreads[0], items, true)
            }}
            className="w-[15rem] h-12 bg-rose-600 ring-2 ring-offset-2 hover:ring-offset-4 hover:scale-[102%] hover:-translate-y-1 hover:shadow-lg ring-rose-500 duration-200 text-white text-lg font-bold flex flex-row items-center justify-center gap-2 group"
          >
            <BookOpenIcon className="size-8 group-hover:animate-bounce" /> GENERATE SPREAD
          </button>
          {catalogSpread?.id && (
            <>
              <button
                onClick={() => saveSpread(catalogSpread, spreads[0], items)}
                className="w-[15rem] h-12 bg-green-600 ring-2 ring-offset-2 hover:ring-offset-4 hover:scale-[102%] hover:-translate-y-1 hover:shadow-lg ring-lime-400 duration-200 text-white text-lg font-bold flex flex-row items-center justify-center gap-2 group"
              >
                <CloudArrowUpIcon className="size-8 group-hover:animate-bounce" /> SAVE SPREAD
              </button>
              {/* <button
                    onClick={() => saveSpread(catalogSpread, spreads[0], items, true)}
                    className="w-[15rem] h-12 bg-green-600 ring-2 ring-offset-2 hover:ring-offset-4 hover:scale-[102%] hover:-translate-y-1 hover:shadow-lg ring-lime-400 duration-200 text-white text-lg font-bold flex flex-row items-center justify-center gap-2 group"
                  >
                    <CheckCircleIcon className="size-8 group-hover:animate-bounce" /> SAVE AS DONE
                    SPREAD
                  </button> */}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
Spread.propTypes = {
  spread: PropTypes.array,
  ProductCard: PropTypes.func,
  generateSpread: PropTypes.func,
  holdItem: PropTypes.func,
  removeItem: PropTypes.func,
  currentSpread: PropTypes.number,
  setCurrentSpread: PropTypes.func,
  spreads: PropTypes.array,
  changeSpreadRow: PropTypes.func,
  changeSpreadPageConfig: PropTypes.func,
  updatePageNumber: PropTypes.func,
  lockItem: PropTypes.func,
  updateItem: PropTypes.func,
  isUsed: PropTypes.func,
  deleteSpreads: PropTypes.func,
  onDragEnd: PropTypes.func,
  sensors: PropTypes.func,
  items: PropTypes.array,
  catalogSpread: PropTypes.object,
  saveSpread: PropTypes.func,
  setItem: PropTypes.func,
  setItemHover: PropTypes.func,
  productInfo: PropTypes.object,
}

export default Spread
