import * as React from 'react'
import PropTypes from 'prop-types'
import {
  ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/solid'

const ProductDetails = ({ item, open, setOpen, catalogSpread, spreads, issue, issueYear }) => {
  // const buttons = open === 0 ? '-left-10' : '-left-[400px]'
  let otherSpreads = spreads.filter(
    spread => (catalogSpread?.id || -1) !== spread.id && spread.items
  )

  let usages = !item
    ? []
    : otherSpreads.filter(spread =>
        (spread?.items?.items || []).some(
          spreadItem => spreadItem.sku.toLowerCase() === (item?.sku || '').toLowerCase()
        )
      )
  const currentMonthPages = (item?.usages || []).filter(
    path =>
      path.startsWith(`/${issueYear} -`) && path.toLowerCase().includes((issue || '').toLowerCase())
  )
  // 3. Filter out spreads that include the provided pageNumber
  const otherSpreadUsage = currentMonthPages
    .map(path => {
      const regex = /(\d+)[\s-]+(\d+)/
      const match = path.match(regex)
      if (match) {
        // Convert the captured strings to numbers
        return [parseInt(match[1], 10), parseInt(match[2], 10), path]
      }
      return [path, path, path]
    })
    .filter(spread => spread !== null)
    .filter(spread => !spread.includes(catalogSpread.page))
    .filter(spread => !usages.some(usage => spread.includes(parseInt(usage.page))))

  return (
    <div className="fixed top-0 right-0 bottom-0 z-[1000] flex flex-row h-full">
      <div
        className={
          (open === 0 ? '-left-12' : '-left-[400px]') +
          ' absolute top-0 w-12  h-full flex flex-col z-[1001] duration-300 border-r'
        }
      >
        <button
          onClick={() => setOpen(o => (o === 1 ? 0 : 1))}
          className={
            (open === 1 ? 'bg-blue-700' : 'bg-gray-500') +
            ' text-white h-16 flex justify-center items-center rounded-l-lg border-b border-b-gray-800 border-t border-t-gray-200'
          }
        >
          <ClipboardDocumentListIcon className="w-8 text-white" />
        </button>
        <button
          onClick={() => setOpen(o => (o === 2 ? 0 : 2))}
          className={
            (open === 2 ? 'bg-amber-500' : 'bg-gray-500') +
            ' text-white h-16 flex justify-center items-center rounded-l-lg border-b border-b-gray-800 border-t border-t-gray-200  '
          }
        >
          <DocumentDuplicateIcon className="w-8 text-white" />
        </button>
        <button
          onClick={() => setOpen(o => (o === 3 ? 0 : 3))}
          className={
            (open === 3 ? 'bg-teal-600 ' : ' bg-gray-500 ') +
            ' text-white h-16 flex justify-center items-center rounded-l-lg border-b border-b-gray-800 border-t border-t-gray-200 '
          }
        >
          <ArchiveBoxIcon className="w-8 text-white" />
        </button>
      </div>
      <div
        className={
          (open === 0 ? 'opacity-0 w-[0px]' : 'opacity-100 delay-150 w-[350px]') +
          '  absolute top-0 right-0 h-full duration-500 bg-white'
        }
      >
        {open === 1 && (
          <div className={' w-[350px]  right-0 top-0 h-full flex flex-col gap-2 p-3'}>
            <p className="font-bold text-xl uppercase">Product Details</p>
            {item ? (
              <>
                <b>Brand</b>
                <p>{item.brand}</p>
                <b>Model</b>
                <p>{item.model}</p>
                <b>Reference Number</b>
                <p>{item.model_no}</p>
                <b>Case Size</b>
                <p>{item.case_size}</p>
                <b>Metals / Materials</b>
                <p>{(item.metals || []).join(', ')}</p>
                <b>Item Name</b>
                <p>{item.name}</p>
                <b>Item Price</b>
                <p>{item.price}</p>
                <b>Item SKU</b>
                <p>{item.sku}</p>
                <b>Item Description</b>
                <p>{item.description}</p>
              </>
            ) : (
              'Select a product for Details'
            )}
          </div>
        )}
        {open === 2 && (
          <div className=" w-[350px]  right-0 top-0 h-full">
            <div className={' w-[350px]  right-0 top-0 h-full flex flex-col gap-2 p-3'}>
              <p className="font-bold text-xl">CURRENTLY USED:</p>
              {usages.length === 0 && otherSpreadUsage.length === 0 ? (
                <div>NOT USED IN CURRENT CATALOG</div>
              ) : (
                <div>
                  {usages.length > 0 && (
                    <>
                      <b>Used in Current Catalog:</b>
                      <p className="font-bold">ONLINE</p>
                      <ul className="list-disc pl-3">
                        {usages.map(usage => (
                          <li key={usage.id}>{usage.name} </li>
                        ))}
                      </ul>
                      <hr className="my-5" />
                    </>
                  )}
                  {otherSpreadUsage.length > 0 && (
                    <>
                      <p className="font-bold text-cyan-800">PSD FILES</p>
                      <ul className="list-disc pl-3 text-cyan-800">
                        {otherSpreadUsage.length > 0 &&
                          otherSpreadUsage.map(usage => <li key={usage[2]}>{usage[2]}</li>)}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {open === 3 && (
          <div className=" w-[350px] p-3 right-0 top-0 h-full">
            <p className="font-bold text-xl">PREVIOUS CATALOG USAGE:</p>
            <ul className="list-disc pl-3">
              {(item?.usages || []).map((usage, i) => (
                <li key={usage}>{usage}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
ProductDetails.propTypes = {
  catalogSettings: PropTypes.object,
  replaceAllItems: PropTypes.func,
  updatePageNumber: PropTypes.func,
  setUrl: PropTypes.func,
  setCatalogSpread: PropTypes.func,
  catalogSpread: PropTypes.object,
}

export default ProductDetails
