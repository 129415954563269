import * as React from 'react'

import PropTypes from 'prop-types'
import { CheckIcon, ClockIcon, ListBulletIcon } from '@heroicons/react/24/solid'
import { BookOpen, ChevronLeft } from 'heroicons-react'

const CatalogIndex = ({
  catalogSettings,
  replaceAllItems,
  updatePageNumber,
  setUrl,
  setCatalogSpread,
  catalogSpread,
  updateSpreadsConfig,
}) => {
  const loadSpread = React.useCallback(spread => {
    // console.log('loadSpread', spread)
    // // updatePageNumber(0, spread.page)
    // console.log('replaceAllItems', spread.config.populate)
    setUrl(spread.config.populate)
    setCatalogSpread(spread)
    updateSpreadsConfig(
      spread?.items?.pages || [
        { number: parseInt(spread.page), rows: [4, 4, 4], config: { layout: 'grid' } }, // totalItems will be 11
        { number: parseInt(spread.page) + 1, rows: [4, 4, 4], config: { layout: 'grid' } }, // totalItems will be 12
      ]
    )
    replaceAllItems(spread.config.populate, 0, spread?.items?.items || [])
  }, [])
  const [show, setShow] = React.useState(true)
  const [designer, setDesigner] = React.useState('all')
  const [status, setStatus] = React.useState('all')
  const designerMappings = {
    Dennis: 'bg-blue-200 text-blue-800',
    Hector: 'bg-orange-200 text-orange-800',
    Edwin: 'bg-yellow-200 text-yellow-800',
  }
  const colors = [
    'bg-blue-100 text-blue-800',
    'bg-red-100 text-red-800',
    'bg-indigo-100 text-indigo-800',
    'bg-purple-100 text-purple-800',
    'bg-pink-100 text-pink-800',
    'bg-teal-100 text-teal-800',
    'bg-lime-100 text-lime-800',
    'bg-cyan-100 text-cyan-800',
    'bg-rose-100 text-rose-800',
  ]
  if (!catalogSettings.spreads) return null

  const uniqueDesigners = [...new Set(catalogSettings.spreads.map(spread => spread.designer))]

  return (
    <div className="fixed top-0 left-0 bottom-0 z-[1000] overflow-x-hidden ">
      <button
        onClick={() => setShow(p => !p)}
        className="bg-stone-800 cursor-pointer z-20 p-1 flex items-center justify-center absolute left-2 rounded-full top-2 "
      >
        {show ? (
          <ChevronLeft className="w-6 h-6 inline-block ease-in-out transition-all text-white" />
        ) : (
          <BookOpen className={'w-6 h-6 inline-block ease-in-out transition-all text-white'} />
        )}
      </button>
      <div
        className={
          'bg-white w-[13vw] h-full flex flex-col ease-in-out transition-all border border-gray-200 ' +
          (show ? '' : '-translate-x-[13vw]')
        }
      >
        <h2
          className={
            'text-2xl p-2 font-bold text-gray-800 pl-12 border-b border-gray-200 ' +
            (show ? '' : '')
          }
        >
          Spreads
        </h2>
        <div className="flex flex-row gap-2 items-center justify-between p-2">
          <label>
            DESIGNER:{' '}
            <select onChange={e => setDesigner(e.target.value)}>
              <option value="all">All</option>
              {uniqueDesigners.map(designer => {
                return (
                  <option key={designer} value={designer}>
                    {designer}
                  </option>
                )
              })}
            </select>
          </label>
          <label>
            STATUS:{' '}
            <select onChange={e => setStatus(e.target.value)} value={status}>
              <option value="all">All</option>
              <option value="done">Done</option>
              <option value="saved">Saved</option>
              <option value="pending">Pending</option>
            </select>
          </label>
        </div>
        <div className="overflow-y-auto overflow-x-hidden px-2">
          <div className="flex flex-col">
            {catalogSettings.spreads.map(spread => {
              if (!designerMappings[spread.designer]) {
                designerMappings[spread.designer] = colors.pop()
              }
              const color = designerMappings[spread.designer]

              if (designer !== 'all' && designer !== spread.designer) return null

              if (status !== 'all') {
                if (status === 'done' && !spread.done) return null
                if (status === 'saved' && !spread.items) return null
                if (status === 'pending' && (spread.done || spread.items)) return null
              }

              return (
                <button
                  onClick={() => loadSpread(spread)}
                  key={spread.id}
                  className={
                    'text-left border-t h-20 overflow-hidden flex flex-col' +
                    (catalogSpread?.id === spread.id ? ' bg-amber-100' : '')
                  }
                >
                  <div className="flex flex-row gap-2">
                    <span
                      className={' text-white w-6 h-6 flex flex-row items-center justify-center'}
                    >
                      {spread.done ? (
                        <CheckIcon className="w-6 h-6 p-1 inline-block ease-in-out transition-all bg-red-500" />
                      ) : spread.items ? (
                        <ListBulletIcon className="w-6 h-6 p-1 inline-block ease-in-out transition-all bg-yellow-400" />
                      ) : (
                        <ClockIcon className="w-6 h-6 p-1 inline-block ease-in-out transition-all bg-green-400" />
                      )}
                    </span>
                    <span
                      className={
                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ' +
                        color
                      }
                    >
                      {spread.designer}
                    </span>
                  </div>
                  <div>{spread.name}</div>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
CatalogIndex.propTypes = {
  catalogSettings: PropTypes.object,
  replaceAllItems: PropTypes.func,
  updatePageNumber: PropTypes.func,
  setUrl: PropTypes.func,
  setCatalogSpread: PropTypes.func,
  catalogSpread: PropTypes.object,
}

export default CatalogIndex
